<template>
    <div id="app">
        <h1>Nico Berling</h1>
        <Navbar />
        <router-view></router-view>
    </div>
</template>

<script>
import Navbar from "@/components/Navbar";
export default {
  name: 'App',
    components: {Navbar}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;

}
ul {
    list-style-type: none;
}

li {
    vertical-align: middle;

}
a:link {
    color: black;
    background-color: transparent;
    text-decoration: underline;
}
a:visited {
    color: darkgray;
}

</style>
