<template>
<div class="notfound">
    <h1>Not Found</h1>
    <p>
        Sorry, the page you requested does not exist.
    </p>
    <router-link to="/">Go back Home</router-link>
</div>
</template>

<script>
export default {
    name: "NotFound"
}
</script>

<style scoped>

</style>