<template>
<div class="home">
    <h2>Welcome to my homepage.</h2>
</div>
</template>

<script>
export default {
    name: "Home"
}
</script>

<style scoped>

</style>