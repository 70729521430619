<template>
<ul>
    <li v-for="link in links" v-bind:key="link.name"><h2><a :href="link.link">{{link.name}}</a></h2></li>
</ul>
</template>

<script>
export default {
    name: "Links",
    data() {
        return {
            links: {
                github: {
                    link: "https://github.com/nicoberling", name: "GitHub"
                }
            }
        }
    }
}
</script>

<style scoped>

</style>