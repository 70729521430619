<template>
<div class="navbar">
    <ul>
        <li v-for="route in routes" v-bind:key="route.path"><b><router-link :to=route.path>{{route.name}}</router-link></b></li>
    </ul>
</div>
</template>

<script>
export default {
    name: "Navbar",
    data() {
        return {
        routes: [
            {path: "/", name: "Home"},
            {path: "/links", name: "Links"},
            {path: "/about", name: "About"}
        ]
    }
}
}
</script>

<style scoped>
.navbar {
    text-align: center;
}
ul {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: white;
}

li {
    float: left;
    vertical-align: middle;
}

li a {
    display: block;
    color: #2c3e50;
    text-align: center;
    font-size: larger;
    margin: 10px;
    padding: 12px 12px 9px 12px;
    text-decoration: none;
    background-color: whitesmoke;
}

li a:hover {
    color: white;
    background-color: #282828;
}

.router-link-active  {
    color: white;
    background-color: #111;
}

</style>