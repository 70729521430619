<template>
<div class="about">
    <h2>I'm Nico.</h2>
</div>
</template>

<script>
export default {
    name: "About"
}
</script>

<style scoped>

</style>